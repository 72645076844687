import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'error/:errorCode',
    loadChildren: () => import('./error-routing/error-routing.module').then((m) => m.ErrorRoutingModule),
  },
  {
    path: 'event-details/:eventSource/:id',
    loadChildren: () => import('./pages/event-details/event-details.module').then((m) => m.EventDetailsPageModule),
  },
  {
    path: 'confirmation-page',
    loadChildren: () => import('./pages/confirmation-page/confirmation-page.module').then((m) => m.ConfirmationPagePageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
